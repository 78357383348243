import React, { useState } from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Input,
    Popconfirm,
    Row,
    Space,
    Table,
} from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { ProductTableType } from '~/shared/types/product.type'
import type { ColumnsType } from 'antd/es/table'
import { deleteProduct, getAllProduct } from '~/shared/api/product.api'
import { useMutation, useQuery } from '@tanstack/react-query'
import { formatDate } from '~/shared/utils/date'
import { APP_LOCAL_DATE_FORMAT } from '~/config/constants'

const Product = () => {
    const navigate = useNavigate()
    const [params, setParams] = useState({ page: 1, limit: 10 })
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    const { data, isLoading, refetch, isRefetching } = useQuery({
        queryKey: ['allProduct', params.page],
        queryFn: () => getAllProduct(params),
    })

    const { mutate: mutateDelete } = useMutation(
        ({ id }: { id: string }) => deleteProduct(id),
        { onSettled: () => refetch() },
    )

    const onEdit = (id: string) => {
        return () => navigate({ pathname: id })
    }

    const onDelete = (id: string) => {
        return () => mutateDelete({ id })
    }

    const onNavigateCreate = () => {
        navigate({ pathname: 'create' })
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const onRemoveSelected = () => {
        selectedRowKeys.map((id) => onDelete(id as string))
    }

    const handleChangePage = (page: number, pageSize: number) => {
        setParams({ ...params, page })
    }

    const columns: ColumnsType<ProductTableType> = [
        { title: 'No', render: (text, record, index) => index + 1 },
        { title: 'Name', dataIndex: 'name' },
        {
            title: 'Date',
            dataIndex: 'date',
            render: (value, record, index) =>
                formatDate(record?.date as string, APP_LOCAL_DATE_FORMAT),
        },
        { title: 'Client', dataIndex: 'client' },
        { title: 'Location', dataIndex: 'location' },
        {
            title: '',
            render: (text, record, index) => (
                <Space size="middle">
                    <EditOutlined
                        style={{ color: '#0b8235' }}
                        onClick={onEdit(record._id)}
                    />

                    <Popconfirm
                        title="Sure to delete?"
                        onConfirm={onDelete(record._id)}
                    >
                        <DeleteOutlined style={{ color: '#f81d22' }} />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/">Home</Link>,
                        },
                        {
                            title: 'Products',
                        },
                    ]}
                />
            </Card>

            <Card>
                <Row gutter={[16, 0]}>
                    <Col span={20}>
                        <Input.Search />
                    </Col>
                    <Col span={2}>
                        <Popconfirm
                            title="Sure to delete?"
                            onConfirm={onRemoveSelected}
                        >
                            <Button danger style={{ width: '100%' }}>
                                Delete
                            </Button>
                        </Popconfirm>
                    </Col>

                    <Col span={2}>
                        <Button
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={onNavigateCreate}
                        >
                            Create
                        </Button>
                    </Col>
                </Row>
            </Card>

            <Card>
                <Table
                    columns={columns}
                    dataSource={data?.entities}
                    loading={isLoading || isRefetching}
                    rowKey={(obj) => obj?._id}
                    rowSelection={{ selectedRowKeys, onChange: onSelectChange }}
                    pagination={{
                        pageSize: Number(data?.paginationData?.limit),
                        total: Number(data?.paginationData?.total),
                        onChange: handleChangePage,
                        showSizeChanger: false,
                    }}
                />
            </Card>
        </Space>
    )
}

export default Product
