import React from 'react'
import { Breadcrumb, Button, Card, Form, Space, Spin } from 'antd'
import FormItem from '~/shared/component/ui/FormItem'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { addTeam, getTeam, updateTeam } from '~/shared/api/team.api'
import { ITeamAdd } from '~/shared/types/team.type'
import { upload } from '~/shared/api/upload.api'

const TeamUpdate = () => {
    const { id } = useParams()
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const mutationAdd = useMutation({
        mutationFn: addTeam,
        onSuccess: () => navigate({ pathname: '/team' }),
    })

    const mutationUpdate = useMutation(
        ({ id, params }: { id: string; params: ITeamAdd }) =>
            updateTeam(id, params),
        { onSuccess: () => navigate({ pathname: '/team' }) },
    )

    const mutationUpload = useMutation({
        mutationFn: upload,
    })

    const queryTeam = useQuery({
        queryKey: ['getTeam', id],
        queryFn: () => getTeam(id as string),
        onSuccess: (data) => {
            const team = data?.entity
            form.setFieldsValue({
                ...team,
                name: team?.name,
                image: [{ url: team?.image }],
            })
        },
        enabled: !!id,
    })

    const handleFinish = async (values: any) => {
        if (id) {
            let image = values.image[0]?.url

            if (!image) {
                image = await mutationUpload.mutateAsync(values.image)
            }

            mutationUpdate.mutate({
                id: id,
                params: { ...values, image },
            })
        } else {
            const [image] = await Promise.all([
                mutationUpload.mutateAsync(values.image),
            ])

            mutationAdd.mutate({
                ...values,
                image: image,
            })
        }
    }

    const handleCancel = () => {
        navigate(-1)
    }

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/">Home</Link>,
                        },
                        {
                            title: <Link to="/team">Team</Link>,
                        },
                        {
                            title: 'Create',
                        },
                    ]}
                />
            </Card>

            <Spin spinning={!!id && queryTeam.isLoading}>
                <Form
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 6 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 14 },
                    }}
                    form={form}
                    onFinish={handleFinish}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Card title="Vietnamese">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    name: ['vi', 'role'],
                                    label: 'Role ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    name: ['vi', 'description'],
                                    label: 'Description ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    name: ['vi', 'experience'],
                                    label: 'Experience ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    name: ['vi', 'quote'],
                                    label: 'Quote ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card title="English">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    name: ['en', 'role'],
                                    label: 'Role ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    name: ['en', 'description'],
                                    label: 'Description ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    name: ['en', 'experience'],
                                    label: 'Experience ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    name: ['en', 'quote'],
                                    label: 'Quote ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card title="Japanese">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    name: ['ja', 'role'],
                                    label: 'Role ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    name: ['ja', 'description'],
                                    label: 'Description ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    name: ['ja', 'experience'],
                                    label: 'Experience ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    name: ['ja', 'quote'],
                                    label: 'Quote ',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card>
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    name: 'name',
                                    label: 'Name',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="UPLOAD"
                                formItemOptions={{
                                    name: 'image',
                                    label: 'Image',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{
                                    listType: 'picture-card',
                                    maxCount: 1,
                                }}
                            />
                        </Card>

                        <Card>
                            <Space size={'large'}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={
                                        mutationUpdate.isLoading ||
                                        mutationAdd.isLoading
                                    }
                                >
                                    Save
                                </Button>
                                <Button onClick={handleCancel}>Cancel</Button>
                            </Space>
                        </Card>
                    </Space>
                </Form>
            </Spin>
        </Space>
    )
}

export default TeamUpdate
