import React from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, Space } from 'antd'
import { useMutation } from '@tanstack/react-query'
import { login } from '~/shared/api/user.api'
import { useNavigate } from 'react-router-dom'
import Logo from '~/shared/assets/images/frovis-big.png'
const Login = () => {
    const navigate = useNavigate()
    const mutationLogin = useMutation({
        mutationFn: login,
        onSuccess: () => navigate(0),
    })

    const onFinish = (values: any) => {
        mutationLogin.mutate(values)
    }

    return (
        <Space
            size={'large'}
            direction="vertical"
            align="center"
            style={{ width: '100%', height: '100vh', justifyContent: 'center' }}
        >
            <img src={Logo} width={198} alt="logo" />
            <Form initialValues={{ remember: true }} onFinish={onFinish}>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                    <Input
                        prefix={
                            <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Username"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                    ]}
                >
                    <Input
                        prefix={
                            <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                    >
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        </Space>
    )
}

export default Login
