import { useMutation, useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Card, Form, Space, Spin } from 'antd'
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { addSummary, getSummary, updateSummary } from '~/shared/api/summary.api'
import { upload } from '~/shared/api/upload.api'
import FormItem from '~/shared/component/ui/FormItem'
import { ISummaryAdd } from '~/shared/types/summary.type'

const SummaryUpdate = () => {
    const { id } = useParams()
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const mutationAdd = useMutation({
        mutationFn: addSummary,
        onSuccess: () => navigate({ pathname: '/summary' }),
    })

    const mutationUpdate = useMutation(
        ({ id, params }: { id: string; params: ISummaryAdd }) =>
            updateSummary(id, params),
        { onSuccess: () => navigate({ pathname: '/summary' }) },
    )

    const mutationUpload = useMutation({
        mutationFn: upload,
    })

    const querySummary = useQuery({
        queryKey: ['getSummary', id],
        queryFn: () => getSummary(id as string),
        onSuccess: (data) => {
            const summary = data?.entity
            form.setFieldsValue({
                ...summary,
                icon: [{ url: summary?.icon }],
                value: summary?.value,
            })
        },
        enabled: !!id,
    })

    const handleFinish = async (values: any) => {
        if (id) {
            let icon = values.icon[0]?.url

            if (!icon) {
                icon = await mutationUpload.mutateAsync(values.icon)
            }

            mutationUpdate.mutate({
                id: id,
                params: { ...values, icon },
            })
        } else {
            const [icon] = await Promise.all([
                mutationUpload.mutateAsync(values.icon),
            ])

            mutationAdd.mutate({
                ...values,
                icon,
            })
        }
    }

    const handleCancel = () => {
        navigate(-1)
    }

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/">Home</Link>,
                        },
                        {
                            title: <Link to="/summary">Summary</Link>,
                        },
                        {
                            title: 'Create',
                        },
                    ]}
                />
            </Card>

            <Spin spinning={!!id && querySummary.isLoading}>
                <Form
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 6 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 14 },
                    }}
                    form={form}
                    onFinish={handleFinish}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Card title="Vietnamese">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Name ',
                                    name: ['vi', 'name'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card title="English">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Name ',
                                    name: ['en', 'name'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>
                        <Card title="Japanese">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Name ',
                                    name: ['ja', 'name'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>
                        <Card>
                            <FormItem
                                type="UPLOAD"
                                formItemOptions={{
                                    label: 'Icon',
                                    name: 'icon',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{
                                    listType: 'picture-card',
                                    maxCount: 1,
                                }}
                            />

                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Value',
                                    name: 'value',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="CHECKBOX"
                                formItemOptions={{
                                    label: 'Has plus',
                                    name: 'suffix',
                                    valuePropName: 'checked',
                                }}
                            />
                        </Card>
                        <Card>
                            <Space size={'large'}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={
                                        mutationUpdate.isLoading ||
                                        mutationAdd.isLoading
                                    }
                                >
                                    Save
                                </Button>
                                <Button onClick={handleCancel}>Cancel</Button>
                            </Space>
                        </Card>
                    </Space>
                </Form>
            </Spin>
        </Space>
    )
}

export default SummaryUpdate
