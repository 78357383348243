import React from 'react'
import { Layout } from 'antd'

const { Footer } = Layout

const TheFooter = () => {
    return (
        <Footer style={{ textAlign: 'center' }}>
            Frovis ©2023 Created by Frovis Web Team
        </Footer>
    )
}

export { TheFooter }
