import { ISummaryAdd } from './../types/summary.type'
import axios from '~/config/axios-interceptors'
import { stringifyParams } from '../utils/queryString'

const prefix = 'summary'

export const getAllSummary = (query: any) =>
    axios.get(`${prefix}${stringifyParams(query)}`).then((res) => res.data.data)

export const getSummary = (id: string) =>
    axios.get(`${prefix}/${id}`).then((res) => res.data.data)

export const addSummary = (params: ISummaryAdd) =>
    axios.post(`${prefix}`, params)

export const updateSummary = (id: string, params: ISummaryAdd) =>
    axios.patch(`${prefix}/${id}`, params)

export const deleteSummary = (id: string) => axios.delete(`${prefix}/${id}`)
