import { notification } from 'antd'
import { isArray } from 'lodash'
import axios from '~/config/axios-interceptors'

const prefix = 'upload'

export const upload = async (files?: any) => {
    const formData = new FormData()
    if (isArray(files)) {
        files.forEach((file: any) => {
            formData.append('image', file.originFileObj)
        })
    } else {
        formData.append('image', files)
    }

    const images = await axios
        .post(`${prefix}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => res.data)
        .catch((err) => notification.error({ message: err.message }))

    return images
}

export const multipleUpload = async (files?: any) => {
    const formData = new FormData()
    if (isArray(files)) {
        files.forEach((file: any) => {
            formData.append('image', file.originFileObj)
        })
    } 

    const images = await axios
        .post(`${prefix}/multiple`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => res.data)
        .catch((err) => notification.error({ message: err.message }))

    return images
}

