import axios from '~/config/axios-interceptors'
import { ITeamAdd } from '../types/team.type'
import { stringifyParams } from '../utils/queryString'

const prefix = 'team'

export const getAllTeam = (query: any) =>
    axios.get(`${prefix}${stringifyParams(query)}`).then((res) => res.data.data)

export const getTeam = (id: string) =>
    axios.get(`${prefix}/${id}`).then((res) => res.data.data)

export const addTeam = (params: ITeamAdd) => axios.post(`${prefix}`, params)

export const updateTeam = (id: string, params: ITeamAdd) =>
    axios.patch(`${prefix}/${id}`, params)

export const deleteTeam = (id: string) => axios.delete(`${prefix}/${id}`)
