import axios from '~/config/axios-interceptors'
import { ILogin } from '~/shared/types/user.type'
import { setCookie } from '../utils'
import { TOKEN } from '~/config/constants'

const prefix = 'user'

export const login = (params: ILogin) =>
    axios
        .post(`${prefix}/login`, params)
        .then((res) => setCookie(TOKEN, res.data.token))
