import React from 'react'
import { Breadcrumb, Button, Card, Form, Space, Spin } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FormItem from '~/shared/component/ui/FormItem'
import { useMutation, useQueries } from '@tanstack/react-query'
import { addProduct, getProduct, updateProduct } from '~/shared/api/product.api'
import { IProductAdd } from '~/shared/types/product.type'
import { multipleUpload, upload } from '~/shared/api/upload.api'
import { APP_LOCAL_DATE_FORMAT } from '~/config/constants'
import dayjs from 'dayjs'
import { getAllCategory } from '~/shared/api/category.api'
import { ICategory } from '~/shared/types/category.type'
import { hasIn } from 'lodash'

const ProductUpdate = () => {
    const { id } = useParams()
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const mutationAdd = useMutation({
        mutationFn: addProduct,
        onSuccess: () => navigate({ pathname: '/product' }),
    })

    const mutationUpdate = useMutation(
        ({ id, params }: { id: string; params: IProductAdd }) =>
            updateProduct(id, params),
        { onSuccess: () => navigate({ pathname: '/product' }) },
    )

    const mutationUpload = useMutation({
        mutationFn: upload,
    })

    const mutationMultipleUpload = useMutation({
        mutationFn: multipleUpload,
    })


    const [queryProduct, queryCategory] = useQueries({
        queries: [
            {
                queryKey: ['getProduct', id],
                queryFn: () => getProduct(id as string),
                onSuccess: (data: any) => {
                    const product = data?.entity
                    form.setFieldsValue({
                        ...product,
                        name: product?.name,
                        image: [{ url: product?.image }],
                        thumbnail: [{ url: product?.thumbnail }],
                        technologyImages: product?.technologyImages.map((item: any)=>({url:item})),
                        date: dayjs(product?.date),
                        client: product?.client,
                        website: product?.website,
                        location: product?.location,
                        value: product?.value,
                        category: product?.category?._id,
                        socialNetwork: product?.socialNetwork,
                        categories: product?.categories?.map(
                            (item: ICategory) => item._id,
                        ),
                    })
                },
                enabled: !!id,
            },
            {
                queryKey: ['allCategory'],
                queryFn: getAllCategory,
            },
        ],
    })

    const categoryOptions = queryCategory.data
        ? queryCategory.data.entities.map(({ _id, en }: any) => ({
              value: _id,
              label: en.title,
          }))
        : []

    const handleFinish = async (values: any) => {
        if (id) {
            let image = values.image[0]?.url
            let thumbnail = values.thumbnail[0]?.url
            let technologyImages


            if (!image) {
                image = await mutationUpload.mutateAsync(values.image)
            }

            if (!thumbnail) {
                thumbnail = await mutationUpload.mutateAsync(values.thumbnail)
            }

            if (values.technologyImages.length > 0) {

                const upload = await mutationMultipleUpload.mutateAsync(values.technologyImages.filter((img:any)=>!hasIn(img,"url")))
                technologyImages = [...upload, ...values.technologyImages.filter((img:any)=>hasIn(img,"url")).map((img:any)=>img.url)]
            }



            mutationUpdate.mutate({
                id: id,
                params: { ...values, image, thumbnail,technologyImages },
            })
        } else {
            const [image, thumbnail] = await Promise.all([
                mutationUpload.mutateAsync(values.image),
                mutationUpload.mutateAsync(values.thumbnail),
            ])

            const technologyImages = await mutationMultipleUpload.mutateAsync(values.technologyImages)

            console.log(technologyImages)

            mutationAdd.mutate({
                ...values,
                technologyImages,
                image,
                thumbnail,
            })
        }
    }

    const handleCancel = () => {
        navigate(-1)
    }

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/">Home</Link>,
                        },
                        {
                            title: <Link to="/product">Product</Link>,
                        },
                        {
                            title: 'Create',
                        },
                    ]}
                />
            </Card>

            <Spin spinning={queryProduct.isLoading && queryCategory.isLoading}>
                <Form
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 6 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 14 },
                    }}
                    form={form}
                    onFinish={handleFinish}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Card title="Vietnamese">
                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'About this project ',
                                    name: ['vi', 'about'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Project challenges ',
                                    name: ['vi', 'challenge'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'End to end development ',
                                    name: ['vi', 'e2eDevelopment'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Technologies',
                                    name: ['vi', 'technologies'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card title="English">
                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'About this project ',
                                    name: ['en', 'about'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Project challenges ',
                                    name: ['en', 'challenge'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'End to end development ',
                                    name: ['en', 'e2eDevelopment'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Technologies',
                                    name: ['en', 'technologies'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card title="Japanese">
                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'About this project ',
                                    name: ['ja', 'about'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Project challenges ',
                                    name: ['ja', 'challenge'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'End to end development ',
                                    name: ['ja', 'e2eDevelopment'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Technologies',
                                    name: ['ja', 'technologies'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card>
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Name',
                                    name: 'name',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="UPLOAD"
                                formItemOptions={{
                                    label: 'Image',
                                    name: 'image',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{
                                    listType: 'picture-card',
                                    maxCount: 1,
                                }}
                            />

                            <FormItem
                                type="UPLOAD"
                                formItemOptions={{
                                    label: 'Thumbnail',
                                    name: 'thumbnail',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{
                                    listType: 'picture-card',
                                    maxCount: 1,
                                }}
                            />

                            <FormItem
                                type="UPLOAD"
                                formItemOptions={{
                                    label: 'Technology images',
                                    name: 'technologyImages',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{
                                    listType: 'picture-card',
                                    // maxCount: 5,
                                }}
                            />

                            <FormItem
                                type="DATEPICKER"
                                formItemOptions={{
                                    label: 'Date',
                                    name: 'date',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{ format: APP_LOCAL_DATE_FORMAT }}
                            />
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Client',
                                    name: 'client',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Website',
                                    name: 'website',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Location',
                                    name: 'location',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Value',
                                    name: 'value',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="SELECT"
                                formItemOptions={{
                                    label: 'Category',
                                    name: 'categories',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{
                                    options: categoryOptions,
                                    mode: 'multiple',
                                }}
                            />
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Twitter',
                                    name: ['socialNetwork', 'twitter'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Facebook',
                                    name: ['socialNetwork', 'facebook'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Pinterest',
                                    name: ['socialNetwork', 'pinterest'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Instagram',
                                    name: ['socialNetwork', 'instagram'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card>
                            <Space size={'large'}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={
                                        mutationUpdate.isLoading ||
                                        mutationAdd.isLoading
                                    }
                                >
                                    Save
                                </Button>
                                <Button onClick={handleCancel}>Cancel</Button>
                            </Space>
                        </Card>
                    </Space>
                </Form>
            </Spin>
        </Space>
    )
}

export default ProductUpdate
