import { useMutation } from '@tanstack/react-query'
import React, { useMemo, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { upload } from '~/shared/api/upload.api'

interface OnChangeHandler {
    (e: any): void
}

type Props = {
    value?: string
    placeholder?: string
    onChange?: OnChangeHandler
}

function Editor({ value, onChange, placeholder }: Props) {
    const mutationUpload = useMutation({
        mutationFn: upload,
    })

    const quillRef: any = useRef()

    const imageHandler = () => {
        // get editor
        const editor = quillRef.current.getEditor()
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        input.click()
        input.onchange = async () => {
            if (input.files?.length) {
                console.log()
                const [image] = await Promise.all([
                    mutationUpload.mutateAsync(input.files[0]),
                ])

                console.log(image)
                try {
                    const link = image
                    editor.insertEmbed(editor.getSelection(), 'image', link)
                } catch (err) {
                    console.log('upload err:', err)
                }
            }
        }
    }

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' },
                    ],
                    ['link', 'image'],
                    ['clean'],
                ],
                handlers: {
                    image: imageHandler,
                },
                clipboard: {
                    matchVisual: false,
                },
            },
        }),
        [],
    )

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ]

    return (
        <>
            <ReactQuill
                ref={quillRef}
                theme="snow"
                modules={modules}
                formats={formats}
                onChange={onChange}
                placeholder={placeholder}
                value={value || ''}
            />
        </>
    )
}

export default Editor
