import axios from '~/config/axios-interceptors'
import { INewsAdd } from '~/shared/types/news.type'
import { stringifyParams } from '../utils/queryString'

const prefix = 'news'

export const getAllNews = (query: any) =>
    axios.get(`${prefix}${stringifyParams(query)}`).then((res) => res.data.data)

export const getNews = (id: string) =>
    axios.get(`${prefix}/${id}`).then((res) => res.data.data)

export const addNews = (params: INewsAdd) =>
    axios.post(`${prefix}`, params, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

export const updateNews = (id: string, params: INewsAdd) =>
    axios.patch(`${prefix}/${id}`, params, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

export const deleteNews = (id: string) =>
    axios.delete(`${prefix}/${id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
