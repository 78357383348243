import React from 'react'
import { Layout } from 'antd'

const { Content } = Layout

interface ITheContent {
    children: React.ReactNode
}

const TheContent = ({ children }: ITheContent) => {
    return (
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
            <div
                style={{
                    padding: 24,
                    textAlign: 'center',
                }}
            >
                {children}
            </div>
        </Content>
    )
}

export { TheContent }
