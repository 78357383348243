import { ISliderAdd } from './../types/slider.type'
import axios from '~/config/axios-interceptors'
import { stringifyParams } from '../utils/queryString'

const prefix = 'slider'

export const getAllSlider = (query: any) =>
    axios.get(`${prefix}${stringifyParams(query)}`).then((res) => res.data.data)

export const getSlider = (id: string) =>
    axios.get(`${prefix}/${id}`).then((res) => res.data.data)

export const addSlider = (params: ISliderAdd) => axios.post(`${prefix}`, params)

export const updateSlider = (id: string, params: ISliderAdd) =>
    axios.patch(`${prefix}/${id}`, params)

export const deleteSlider = (id: string) => axios.delete(`${prefix}/${id}`)
