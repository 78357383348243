import axios from '~/config/axios-interceptors'
import { IProductAdd } from '../types/product.type'
import { stringifyParams } from '../utils/queryString'

const prefix = 'product'

export const getAllProduct = (query: any) =>
    axios.get(`${prefix}${stringifyParams(query)}`).then((res) => res.data.data)

export const getProduct = (id: string) =>
    axios.get(`${prefix}/${id}`).then((res) => res.data.data)

export const addProduct = (params: IProductAdd) =>
    axios.post(`${prefix}`, params)

export const updateProduct = (id: string, params: IProductAdd) =>
    axios.patch(`${prefix}/${id}`, params)

export const deleteProduct = (id: string) => axios.delete(`${prefix}/${id}`)
