import React from 'react'
import { Button, Space, Form, Divider, Spin, Card, Breadcrumb } from 'antd'
import FormItem from '~/shared/component/ui/FormItem'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
    addService,
    getAllService,
    getService,
    updateService,
} from '~/shared/api/service.api'
import { IServiceAdd } from '~/shared/types/service.type'
import { upload } from '~/shared/api/upload.api'
import { isUndefined } from 'lodash'

const ServicesUpdate = () => {
    const { id } = useParams()
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const { mutate: mutateAdd } = useMutation({
        mutationFn: addService,
        onSuccess: () => navigate({ pathname: '/services' }),
    })

    const queryAllService = useQuery({
        queryKey: ['allService'],
        queryFn: () => getAllService(),
    })

    const mutationUpdate = useMutation(
        ({ id, params }: { id: string; params: IServiceAdd }) =>
            updateService(id, params),
        { onSuccess: () => navigate({ pathname: '/services' }) },
    )

    const mutationUpload = useMutation({
        mutationFn: upload,
    })

    const queryService = useQuery({
        queryKey: ['getService', id],
        queryFn: () => getService(id as string),
        onSuccess: (data) => {
            const service = data?.entity
            form.setFieldsValue({
                ...service,
                // name: service?.name,
                // shortDescription: service?.shortDescription,
                icon: [{ url: service?.icon }],
                image: [{ url: service?.image }],
                // serviceOverview: service?.serviceOverview,
                // serviceCenter: service?.serviceCenter,
                // benefit: service?.benefit,
                // faqs: service?.faqs,
            })
        },
        enabled: !!id,
    })

    const handleFinish = async (values: any) => {
        if (id) {
            let image = values.image[0]?.url
            let icon = values.icon[0]?.url

            if (!image) {
                image = await mutationUpload.mutateAsync(values.image)
            }

            if (!icon) {
                icon = await mutationUpload.mutateAsync(values.icon)
            }

            mutationUpdate.mutate({
                id: id,
                params: {
                    ...values,
                    image,
                    icon,
                    parentID: values.parentID || null,
                },
            })
        } else {
            const [image, icon] = await Promise.all([
                mutationUpload.mutateAsync(values.image),
                mutationUpload.mutateAsync(values.icon),
            ])

            mutateAdd({
                ...values,
                image: image,
                icon: icon,
            })
        }
    }

    const handleCancel = () => {
        navigate(-1)
    }

    const servicesOptions = queryAllService.data
        ? queryAllService.data.entities
              .filter(({ parentID, _id }: any) => !parentID && _id !== id)
              .map(({ _id, en }: any) => ({
                  value: _id,
                  label: en.name,
              }))
        : []

    console.log(servicesOptions)

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/">Home</Link>,
                        },
                        {
                            title: <Link to="/services">Services</Link>,
                        },
                        {
                            title: 'Create',
                        },
                    ]}
                />
            </Card>
            <Spin spinning={!!id && queryService.isLoading}>
                <Form
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 6 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 14 },
                    }}
                    form={form}
                    onFinish={handleFinish}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Card title="Vietnamese">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Name ',
                                    name: ['vi', 'name'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Short description ',
                                    name: ['vi', 'shortDescription'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Service overview ',
                                    name: ['vi', 'serviceOverview'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Service center ',
                                    name: ['vi', 'serviceCenter'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <p>List FAQ </p>

                            <Form.List name={['vi', 'faqs']}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <React.Fragment key={field.key}>
                                                <Divider>
                                                    FAQ {index + 1}
                                                </Divider>

                                                <FormItem
                                                    type="INPUT"
                                                    formItemOptions={{
                                                        label: 'Title',
                                                        name: [
                                                            field.name,
                                                            'title',
                                                        ],
                                                        rules: [
                                                            {
                                                                required: true,
                                                            },
                                                        ],
                                                    }}
                                                />
                                                <FormItem
                                                    type="INPUT"
                                                    formItemOptions={{
                                                        label: 'Description',
                                                        name: [
                                                            field.name,
                                                            'description',
                                                        ],
                                                        rules: [
                                                            {
                                                                required: true,
                                                            },
                                                        ],
                                                    }}
                                                />
                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        onClick={() =>
                                                            remove(field.name)
                                                        }
                                                        // style={{ width: '100%' }}
                                                    >
                                                        <DeleteOutlined />{' '}
                                                        Remove field
                                                    </Button>
                                                </Form.Item>
                                            </React.Fragment>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                // style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Add field
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <p>List benefit </p>

                            <Form.List name={['vi', 'benefit']}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <React.Fragment key={field.key}>
                                                <Divider>
                                                    Benefit {index + 1}
                                                </Divider>

                                                <FormItem
                                                    type="INPUT"
                                                    formItemOptions={{
                                                        label: 'Title',
                                                        name: [
                                                            field.name,
                                                            'title',
                                                        ],
                                                        rules: [
                                                            {
                                                                required: true,
                                                            },
                                                        ],
                                                    }}
                                                />

                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        onClick={() =>
                                                            remove(field.name)
                                                        }
                                                        // style={{ width: '100%' }}
                                                    >
                                                        <DeleteOutlined />{' '}
                                                        Remove field
                                                    </Button>
                                                </Form.Item>
                                            </React.Fragment>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                // style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Add field
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>

                        <Card title="English">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Name ',
                                    name: ['en', 'name'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Short description ',
                                    name: ['en', 'shortDescription'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Service overview ',
                                    name: ['en', 'serviceOverview'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Service center ',
                                    name: ['en', 'serviceCenter'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <p>List FAQ </p>

                            <Form.List name={['en', 'faqs']}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <React.Fragment key={field.key}>
                                                <Divider>
                                                    FAQ {index + 1}
                                                </Divider>

                                                <FormItem
                                                    type="INPUT"
                                                    formItemOptions={{
                                                        label: 'Title',
                                                        name: [
                                                            field.name,
                                                            'title',
                                                        ],
                                                        rules: [
                                                            {
                                                                required: true,
                                                            },
                                                        ],
                                                    }}
                                                />
                                                <FormItem
                                                    type="INPUT"
                                                    formItemOptions={{
                                                        label: 'Description',
                                                        name: [
                                                            field.name,
                                                            'description',
                                                        ],
                                                        rules: [
                                                            {
                                                                required: true,
                                                            },
                                                        ],
                                                    }}
                                                />
                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        onClick={() =>
                                                            remove(field.name)
                                                        }
                                                        // style={{ width: '100%' }}
                                                    >
                                                        <DeleteOutlined />{' '}
                                                        Remove field
                                                    </Button>
                                                </Form.Item>
                                            </React.Fragment>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                // style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Add field
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <p>List benefit </p>

                            <Form.List name={['en', 'benefit']}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <React.Fragment key={field.key}>
                                                <Divider>
                                                    Benefit {index + 1}
                                                </Divider>

                                                <FormItem
                                                    type="INPUT"
                                                    formItemOptions={{
                                                        label: 'Title',
                                                        name: [
                                                            field.name,
                                                            'title',
                                                        ],
                                                        rules: [
                                                            {
                                                                required: true,
                                                            },
                                                        ],
                                                    }}
                                                />

                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        onClick={() =>
                                                            remove(field.name)
                                                        }
                                                        // style={{ width: '100%' }}
                                                    >
                                                        <DeleteOutlined />{' '}
                                                        Remove field
                                                    </Button>
                                                </Form.Item>
                                            </React.Fragment>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                // style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Add field
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>

                        <Card title="Japanese">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Name ',
                                    name: ['ja', 'name'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Short description ',
                                    name: ['ja', 'shortDescription'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Service overview ',
                                    name: ['ja', 'serviceOverview'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="TEXTAREA"
                                formItemOptions={{
                                    label: 'Service center ',
                                    name: ['ja', 'serviceCenter'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <p>List FAQ </p>

                            <Form.List name={['ja', 'faqs']}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <React.Fragment key={field.key}>
                                                <Divider>
                                                    FAQ {index + 1}
                                                </Divider>

                                                <FormItem
                                                    type="INPUT"
                                                    formItemOptions={{
                                                        label: 'Title',
                                                        name: [
                                                            field.name,
                                                            'title',
                                                        ],
                                                        rules: [
                                                            {
                                                                required: true,
                                                            },
                                                        ],
                                                    }}
                                                />
                                                <FormItem
                                                    type="INPUT"
                                                    formItemOptions={{
                                                        label: 'Description',
                                                        name: [
                                                            field.name,
                                                            'description',
                                                        ],
                                                        rules: [
                                                            {
                                                                required: true,
                                                            },
                                                        ],
                                                    }}
                                                />
                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        onClick={() =>
                                                            remove(field.name)
                                                        }
                                                        // style={{ width: '100%' }}
                                                    >
                                                        <DeleteOutlined />{' '}
                                                        Remove field
                                                    </Button>
                                                </Form.Item>
                                            </React.Fragment>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                // style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Add field
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <p>List benefit </p>

                            <Form.List name={['ja', 'benefit']}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <React.Fragment key={field.key}>
                                                <Divider>
                                                    Benefit {index + 1}
                                                </Divider>

                                                <FormItem
                                                    type="INPUT"
                                                    formItemOptions={{
                                                        label: 'Title',
                                                        name: [
                                                            field.name,
                                                            'title',
                                                        ],
                                                        rules: [
                                                            {
                                                                required: true,
                                                            },
                                                        ],
                                                    }}
                                                />

                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        onClick={() =>
                                                            remove(field.name)
                                                        }
                                                        // style={{ width: '100%' }}
                                                    >
                                                        <DeleteOutlined />{' '}
                                                        Remove field
                                                    </Button>
                                                </Form.Item>
                                            </React.Fragment>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                // style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Add field
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>

                        <Card>
                            <FormItem
                                type="UPLOAD"
                                formItemOptions={{
                                    label: 'Icon',
                                    name: 'icon',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{
                                    listType: 'picture-card',
                                    maxCount: 1,
                                }}
                            />

                            <FormItem
                                type="UPLOAD"
                                formItemOptions={{
                                    label: 'Image',
                                    name: 'image',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{
                                    listType: 'picture-card',
                                    maxCount: 1,
                                }}
                            />

                            <FormItem
                                type="SELECT"
                                formItemOptions={{
                                    label: 'In which service?',
                                    name: 'parentID',
                                    // rules: [
                                    //     {
                                    //         required: true,
                                    //     },
                                    // ],
                                }}
                                inputOptions={{
                                    options: servicesOptions,
                                    allowClear: true,
                                }}
                            />
                        </Card>
                        <Card>
                            <Space size={'large'}>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                                <Button onClick={handleCancel}>Cancel</Button>
                            </Space>
                        </Card>
                    </Space>
                </Form>
            </Spin>
        </Space>
    )
}

export default ServicesUpdate
