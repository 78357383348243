import { PlusOutlined } from '@ant-design/icons'
import { DatePicker, Form, Input, Select, Upload, Checkbox } from 'antd'

import Editor from './Editor'

const { TextArea } = Input

interface IFormItem {
    type?:
        | 'INPUT'
        | 'UPLOAD'
        | 'TEXTAREA'
        | 'DATEPICKER'
        | 'SELECT'
        | 'EDITOR'
        | 'CHECKBOX'
    formItemOptions?: any
    inputOptions?: any
}

const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e
    }
    return e?.fileList
}

const uploadButton = (
    <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
    </div>
)

const FormItem = ({ type, formItemOptions, inputOptions }: IFormItem) => {
    const renderInput = (type: any) => {
        switch (type) {
            case 'INPUT':
                return (
                    <Form.Item {...formItemOptions}>
                        <Input {...inputOptions} />
                    </Form.Item>
                )
            case 'TEXTAREA':
                return (
                    <Form.Item {...formItemOptions}>
                        <TextArea {...inputOptions} />
                    </Form.Item>
                )
            case 'UPLOAD':
                return (
                    <Form.Item
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        {...formItemOptions}
                    >
                        <Upload
                            name="logo"
                            action="/upload.do"
                            listType="picture-card"
                            beforeUpload={(file) => {
                                const reader = new FileReader()

                                reader.onload = (e: any) => {}
                                reader.readAsText(file)

                                // Prevent upload
                                return false
                            }}
                            {...inputOptions}
                        >
                            {uploadButton}
                        </Upload>
                    </Form.Item>
                )
            case 'DATEPICKER':
                return (
                    <Form.Item {...formItemOptions}>
                        <DatePicker {...inputOptions} />
                    </Form.Item>
                )
            case 'SELECT':
                return (
                    <Form.Item {...formItemOptions}>
                        <Select
                            filterOption={(input, option) =>
                                (option?.label?.toString() ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            showSearch
                            {...inputOptions}
                        />
                    </Form.Item>
                )
            case 'EDITOR':
                return (
                    <Form.Item {...formItemOptions}>
                        <Editor {...inputOptions} />
                    </Form.Item>
                )
            case 'CHECKBOX':
                return (
                    <Form.Item {...formItemOptions}>
                        <Checkbox {...inputOptions} />
                    </Form.Item>
                )
            default:
                return <Input />
        }
    }
    return renderInput(type)
}

export default FormItem
