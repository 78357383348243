import React from 'react'
import { Layout, Menu } from 'antd'
import type { MenuProps } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '~/shared/assets/images/frovis-big.png'
import { eraseCookie } from '../utils'
import { TOKEN } from '~/config/constants'

const { Sider } = Layout

const items = [
    { label: 'Home', key: '/' },
    { label: 'New', key: '/news' },
    { label: 'Product', key: '/product' },
    { label: 'Services', key: '/services' },
    { label: 'Team', key: '/team' },
    { label: 'Category', key: '/category' },
    { label: 'Contact', key: '/contact' },
    { label: 'Summary', key: '/summary' },
    { label: 'Slider', key: '/slider' },
    { label: 'Logout', key: '/logout' },
]

const TheAside = () => {
    const navigate = useNavigate()

    const location = useLocation()

    const handleClick: MenuProps['onClick'] = (e) => {
        if (e.key === '/logout') {
            eraseCookie(TOKEN)
            navigate(0)
        } else {
            navigate(e.key)
        }
    }

    return (
        <Sider
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
        >
            <div
                style={{
                    height: 32,
                    margin: 16,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={Logo}
                    style={{
                        height: '100%',
                        objectFit: 'contain',
                    }}
                    alt="logo"
                />
            </div>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
                items={items}
                onClick={handleClick}
            />
        </Sider>
    )
}

export { TheAside }
