import moment from 'moment'
import { APP_LOCAL_ISO_8601 } from '~/config/constants'

const formatDate = (date: string, format: string = APP_LOCAL_ISO_8601) =>
    moment(date).format(format)

const momentDate = (date: string, format: string = APP_LOCAL_ISO_8601) =>
    moment(date, format)

export { formatDate, momentDate }
