import React from 'react'
import { getCookie } from './shared/utils'
import { TOKEN } from './config/constants'
import TheLayout from './shared/layout/TheLayout'
import Login from './page/Auth/Login'
import Home from './page/Home/Home'
import Product from './page/Product/Product'
import ProductUpdate from './page/Product/ProductUpdate'
import Services from './page/Services/Services'
import ServicesUpdate from './page/Services/ServicesUpdate'
import Team from './page/Team/Team'
import TeamUpdate from './page/Team/TeamUpdate'
import NotFound from './page/Error/NotFound'
import News from './page/News/News'
import NewsUpdate from './page/News/NewsUpdate'
import Category from './page/Category/Category'
import CategoryUpdate from './page/Category/CategoryUpdate'
import Contact from './page/Contact/Contact'
import Summary from './page/Summary/Summary'
import SummaryUpdate from './page/Summary/SummaryUpdate'
import Slider from './page/Slider/Slider'
import SliderUpdate from './page/Slider/SliderUpdate'

const token = getCookie(TOKEN)

const routes = [
    {
        path: '/',
        element: token ? <TheLayout /> : <Login />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: '/news',
                children: [
                    { index: true, element: <News /> },
                    {
                        index: true,
                        path: 'create',
                        element: <NewsUpdate />,
                    },
                    {
                        path: ':id',
                        element: <NewsUpdate />,
                    },
                ],
            },

            {
                path: '/product',
                children: [
                    { index: true, element: <Product /> },
                    {
                        path: 'create',
                        element: <ProductUpdate />,
                    },
                    {
                        path: ':id',
                        element: <ProductUpdate />,
                    },
                ],
            },
            {
                path: '/services',
                children: [
                    { index: true, element: <Services /> },
                    {
                        path: 'create',
                        element: <ServicesUpdate />,
                    },
                    {
                        path: ':id',
                        element: <ServicesUpdate />,
                    },
                ],
            },
            {
                path: '/team',
                children: [
                    { index: true, element: <Team /> },
                    {
                        path: 'create',
                        element: <TeamUpdate />,
                    },
                    {
                        path: ':id',
                        element: <TeamUpdate />,
                    },
                ],
            },
            {
                path: '/category',
                children: [
                    { index: true, element: <Category /> },
                    {
                        path: 'create',
                        element: <CategoryUpdate />,
                    },
                    {
                        path: ':id',
                        element: <CategoryUpdate />,
                    },
                ],
            },
            {
                path: '/contact',
                children: [{ index: true, element: <Contact /> }],
            },
            {
                path: '/summary',
                children: [
                    { index: true, element: <Summary /> },
                    {
                        path: 'create',
                        element: <SummaryUpdate />,
                    },
                    {
                        path: ':id',
                        element: <SummaryUpdate />,
                    },
                ],
            },
            {
                path: '/slider',
                children: [
                    { index: true, element: <Slider /> },
                    {
                        path: 'create',
                        element: <SliderUpdate />,
                    },
                    {
                        path: ':id',
                        element: <SliderUpdate />,
                    },
                ],
            },
        ],
    },
    { path: '*', element: <NotFound /> },
]

export default routes
