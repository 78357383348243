import axios from '~/config/axios-interceptors'
import { IServiceAdd } from '../types/service.type'

const prefix = 'service'

export const getAllService = () =>
    axios.get(`${prefix}`).then((res) => res.data.data)

export const getService = (id: string) =>
    axios.get(`${prefix}/${id}`).then((res) => res.data.data)

export const addService = (params: IServiceAdd) =>
    axios.post(`${prefix}`, params)

export const updateService = (id: string, params: IServiceAdd) =>
    axios.patch(`${prefix}/${id}`, params)

export const deleteService = (id: string) => axios.delete(`${prefix}/${id}`)
