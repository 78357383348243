import React from 'react'
import { Breadcrumb, Button, Card, Form, Space, Spin } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FormItem from '~/shared/component/ui/FormItem'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
    addCategory,
    getCategory,
    updateCategory,
} from '~/shared/api/category.api'
import { ICategoryAdd } from '~/shared/types/category.type'

const CategoryUpdate = () => {
    const { id } = useParams()
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const mutationAdd = useMutation({
        mutationFn: addCategory,
        onSuccess: () => navigate({ pathname: '/category' }),
    })

    const mutationUpdate = useMutation(
        ({ id, params }: { id: string; params: ICategoryAdd }) =>
            updateCategory(id, params),
        { onSuccess: () => navigate({ pathname: '/category' }) },
    )

    const queryCategory = useQuery({
        queryKey: ['getCategory', id],
        queryFn: () => getCategory(id as string),
        onSuccess: (data) => {
            const category = data?.entity
            form.setFieldsValue({
                ...category,
            })
        },
        enabled: !!id,
    })

    const handleFinish = (values: any) => {
        if (id) {
            mutationUpdate.mutate({
                id: id,
                params: values,
            })
        } else {
            mutationAdd.mutate(values)
        }
    }

    const handleCancel = () => {
        navigate(-1)
    }

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/">Home</Link>,
                        },
                        {
                            title: <Link to="/category">Category</Link>,
                        },
                        {
                            title: 'Create',
                        },
                    ]}
                />
            </Card>

            <Spin spinning={!!id && queryCategory.isLoading}>
                <Form
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 6 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 14 },
                    }}
                    form={form}
                    onFinish={handleFinish}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Card title="Vietnamese">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Title ',
                                    name: ['vi', 'title'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card title="English">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Title ',
                                    name: ['en', 'title'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card title="Japanese">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Title ',
                                    name: ['ja', 'title'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>
                        <Card>
                            <Space size={'large'}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={
                                        mutationUpdate.isLoading ||
                                        mutationAdd.isLoading
                                    }
                                >
                                    Save
                                </Button>
                                <Button onClick={handleCancel}>Cancel</Button>
                            </Space>
                        </Card>
                    </Space>
                </Form>
            </Spin>
        </Space>
    )
}

export default CategoryUpdate
