import axios from '~/config/axios-interceptors'
import { stringifyParams } from '../utils/queryString'
// import { IContact } from '../types/contact.type'

const prefix = 'contact'

export const getAllContact = (query: any) =>
    axios.get(`${prefix}${stringifyParams(query)}`).then((res) => res.data.data)

// export const getContact = (id: string) =>
//     axios.get(`${prefix}/${id}`).then((res) => res.data.data)
