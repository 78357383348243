import { useMutation, useQueries } from '@tanstack/react-query'
import { Breadcrumb, Button, Card, Form, Space, Spin } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getAllCategory } from '~/shared/api/category.api'
import { addNews, getNews, updateNews } from '~/shared/api/news.api'
import { upload } from '~/shared/api/upload.api'
import FormItem from '~/shared/component/ui/FormItem'
import { ICategory } from '~/shared/types/category.type'
import { INewsAdd } from '~/shared/types/news.type'

const NewsUpdate = () => {
    const { id } = useParams()
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const mutationAdd = useMutation({
        mutationFn: addNews,
        onSuccess: () => navigate({ pathname: '/news' }),
    })

    const mutationUpdate = useMutation(
        ({ id, params }: { id: string; params: INewsAdd }) =>
            updateNews(id, params),
        { onSuccess: () => navigate({ pathname: '/news' }) },
    )

    const mutationUpload = useMutation({
        mutationFn: upload,
    })

    const [queryNews, queryCategory] = useQueries({
        queries: [
            {
                queryKey: ['news', id],
                queryFn: () => getNews(id as string),
                onSuccess: (data: any) => {
                    const news = data?.entity
                    form.setFieldsValue({
                        ...news,
                        thumbnail: [{ url: news?.thumbnail }],
                        image: [{ url: news?.image }],
                        categories: news?.categories?.map(
                            (item: ICategory) => item._id,
                        ),
                    })
                },
                enabled: !!id,
            },
            {
                queryKey: ['allCategory'],
                queryFn: getAllCategory,
            },
        ],
    })

    const handleFinish = async (values: any) => {
        if (id) {
            let image = values.image[0]?.url
            let thumbnail = values.thumbnail[0]?.url

            if (!image) {
                image = await mutationUpload.mutateAsync(values.image)
            }
            if (!thumbnail) {
                thumbnail = await mutationUpload.mutateAsync(values.thumbnail)
            }

            mutationUpdate.mutate({
                id: id,
                params: { ...values, image, thumbnail },
            })
        } else {
            const [thumbnail, image] = await Promise.all([
                mutationUpload.mutateAsync(values.thumbnail),
                mutationUpload.mutateAsync(values.image),
            ])

            mutationAdd.mutate({
                ...values,
                image: image,
                thumbnail: thumbnail,
            })
        }
    }

    const categoryOptions = queryCategory.data
        ? queryCategory.data.entities.map(({ _id, en }: any) => ({
              value: _id,
              label: en.name,
          }))
        : []

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/">Home</Link>,
                        },
                        {
                            title: <Link to="/news">New</Link>,
                        },
                        {
                            title: 'Create',
                        },
                    ]}
                />
            </Card>

            <Spin spinning={queryNews.isLoading && queryCategory.isLoading}>
                <Form
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 6 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 14 },
                    }}
                    form={form}
                    onFinish={handleFinish}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Card title="Vietnamese">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Title ',
                                    name: ['vi', 'title'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="EDITOR"
                                formItemOptions={{
                                    label: 'Description ',
                                    name: ['vi', 'description'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card title="English">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Title ',
                                    name: ['en', 'title'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />

                            <FormItem
                                type="EDITOR"
                                formItemOptions={{
                                    label: 'Description ',
                                    name: ['en', 'description'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card title="Japanese">
                            <FormItem
                                type="INPUT"
                                formItemOptions={{
                                    label: 'Title ',
                                    name: ['ja', 'title'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                            <FormItem
                                type="EDITOR"
                                formItemOptions={{
                                    label: 'Description ',
                                    name: ['ja', 'description'],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                            />
                        </Card>

                        <Card>
                            <FormItem
                                type="UPLOAD"
                                formItemOptions={{
                                    label: 'Thumbnail',
                                    name: 'thumbnail',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{
                                    listType: 'picture-card',
                                    maxCount: 1,
                                }}
                            />

                            <FormItem
                                type="UPLOAD"
                                formItemOptions={{
                                    label: 'Image',
                                    name: 'image',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{
                                    listType: 'picture-card',
                                    maxCount: 1,
                                }}
                            />

                            <FormItem
                                type="SELECT"
                                formItemOptions={{
                                    label: 'Category',
                                    name: 'categories',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                }}
                                inputOptions={{
                                    options: categoryOptions,
                                    mode: 'multiple',
                                }}
                            />
                        </Card>

                        <Card>
                            <Space size={'large'}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={
                                        mutationUpdate.isLoading ||
                                        mutationAdd.isLoading
                                    }
                                >
                                    Save
                                </Button>
                                <Button>Cancel</Button>
                            </Space>
                        </Card>
                    </Space>
                </Form>
            </Spin>
        </Space>
    )
}

export default NewsUpdate
