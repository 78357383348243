import { Layout } from 'antd'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { TheAside, TheContent, TheFooter, TheHeader } from '~/shared/layout/'

const TheLayout = () => {
    return (
        <Layout hasSider>
            <TheAside />
            <Layout className="site-layout" style={{ marginLeft: 200 }}>
                <TheHeader />
                <TheContent children={<Outlet />} />
                <TheFooter />
            </Layout>
        </Layout>
    )
}

export default TheLayout
