import axios from '~/config/axios-interceptors'
import { ICategoryAdd } from '../types/category.type'

const prefix = 'category'

export const getAllCategory = (query: any) =>
    axios.get(`${prefix}`).then((res) => res.data.data)

export const getCategory = (id: string) =>
    axios.get(`${prefix}/${id}`).then((res) => res.data.data)

export const addCategory = (params: ICategoryAdd) =>
    axios.post(`${prefix}`, params)

export const updateCategory = (id: string, params: ICategoryAdd) =>
    axios.patch(`${prefix}/${id}`, params)

export const deleteCategory = (id: string) => axios.delete(`${prefix}/${id}`)
