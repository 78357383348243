import React from 'react'

import { useRoutes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import '~/style/globals.scss'
import 'antd/dist/reset.css'
import routes from './routes'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
})

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            {useRoutes(routes)}
            <ReactQueryDevtools />
        </QueryClientProvider>
    )
}

export default App
