import React, { useState } from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Input,
    Popconfirm,
    Row,
    Space,
    Table,
} from 'antd'
import { ServiceTableType } from '~/shared/types/service.type'
import type { ColumnsType } from 'antd/es/table'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { deleteService, getAllService } from '~/shared/api/service.api'

const Services = () => {
    const navigate = useNavigate()
    // const [params, setParams] = useState({ page: 1, limit: 10 })
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    const { data, isLoading, refetch, isRefetching } = useQuery({
        queryKey: ['allService'],
        queryFn: () => getAllService(),
    })

    const { mutate: mutateDelete } = useMutation(
        ({ id }: { id: string }) => deleteService(id),
        { onSettled: () => refetch() },
    )

    const onEdit = (id: string) => {
        return () => navigate({ pathname: id })
    }

    const onDelete = (id: string) => {
        return () => mutateDelete({ id })
    }

    const onNavigateCreate = () => {
        navigate({ pathname: 'create' })
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const onRemoveSelected = () => {
        selectedRowKeys.map((id) => onDelete(id as string))
    }

    const handleChangePage = (page: number, pageSize: number) => {
        // setParams({ ...params, page })
    }

    const columns: ColumnsType<ServiceTableType> = [
        { title: 'No', render: (text, record, index) => index + 1 },
        { title: 'Name', dataIndex: ['en', 'name'] },
        { title: 'shortDescription', dataIndex: ['en', 'shortDescription'] },
        { title: 'Create at', dataIndex: 'createAt' },
        { title: 'Create by', dataIndex: 'createBy' },
        {
            title: '',
            render: (text, record, index) => (
                <Space size="middle">
                    <EditOutlined
                        style={{ color: '#0b8235' }}
                        onClick={onEdit(record._id)}
                    />
                    <Popconfirm
                        title="Sure to delete?"
                        onConfirm={onDelete(record._id)}
                    >
                        <DeleteOutlined style={{ color: '#f81d22' }} />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/">Home</Link>,
                        },
                        {
                            title: 'Services',
                        },
                    ]}
                />
            </Card>

            <Card>
                <Row gutter={[16, 0]}>
                    <Col span={20}>
                        <Input.Search />
                    </Col>
                    <Col span={2}>
                        <Popconfirm
                            title="Sure to delete?"
                            onConfirm={onRemoveSelected}
                        >
                            <Button danger style={{ width: '100%' }}>
                                Delete
                            </Button>
                        </Popconfirm>
                    </Col>

                    <Col span={2}>
                        <Button
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={onNavigateCreate}
                        >
                            Create
                        </Button>
                    </Col>
                </Row>
            </Card>

            <Card>
                <Table
                    columns={columns}
                    dataSource={data?.entities}
                    loading={isLoading || isRefetching}
                    rowKey={(obj) => obj?._id}
                    rowSelection={{ selectedRowKeys, onChange: onSelectChange }}
                    pagination={{
                        pageSize: 10,
                        total: Number(data?.paginationData?.total),
                        onChange: handleChangePage,
                        showSizeChanger: false,
                    }}
                />
            </Card>
        </Space>
    )
}

export default Services
