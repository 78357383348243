import { Breadcrumb, Card, Input, Space, Table } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { IContact } from '~/shared/types/contact.type'
import type { ColumnsType } from 'antd/es/table'
import { useQuery } from '@tanstack/react-query'
import { getAllContact } from '~/shared/api/contact.api'

const Contact = () => {
    const [params, setParams] = useState({ page: 1, limit: 10 })

    const { data, isLoading, isRefetching } = useQuery({
        queryKey: ['allCategory', params.page],
        queryFn: () => getAllContact(params),
    })

    const handleChangePage = (page: number, pageSize: number) => {
        setParams({ ...params, page })
    }

    const columns: ColumnsType<IContact> = [
        { title: 'No', render: (text, record, index) => index + 1 },
        { title: 'Name', dataIndex: 'name' },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        { title: 'Phone number', dataIndex: 'phoneNumber' },
        { title: 'Skype', dataIndex: 'skype' },
        { title: 'Subject', dataIndex: 'subject' },
        { title: 'Whatapps', dataIndex: 'whatsapp' },
    ]

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/">Home</Link>,
                        },
                        {
                            title: 'Contact',
                        },
                    ]}
                />
            </Card>

            <Card>
                <Input.Search />
            </Card>

            <Card>
                <Table
                    columns={columns}
                    dataSource={data?.entities}
                    loading={isLoading || isRefetching}
                    rowKey={(obj) => obj?._id}
                    pagination={{
                        pageSize: Number(data?.paginationData?.limit),
                        total: Number(data?.paginationData?.total),
                        onChange: handleChangePage,
                        showSizeChanger: false,
                    }}
                    expandable={{
                        expandedRowRender: (record) => (
                            <p style={{ margin: 0 }}>{record.message}</p>
                        ),
                        rowExpandable: (record) =>
                            record.name !== 'Not Expandable',
                    }}
                />
            </Card>
        </Space>
    )
}

export default Contact
